export const emailRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
"(",
	/[1-9]/,
	/\d/,
	/\d/,
	")",
	" ",
	/\d/,
	/\d/,
	/\d/,
	" ",
	"-",
	" ",
	/\d/,
	/\d/,
	/\d/,
	/\d/;
export const phoneRegex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
export const objectIdRegex = /^[a-f\d]{24}$/i;
export const nameRegex = /^[a-zA-Z ]+$/;
export const zipcodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const youtubeRegex =
	/^(https?:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;
// eslint-disable-next-line no-useless-escape
export const facebookRegex =
	/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/;
export const linkedinRegex =
	/http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
export const twitterRegex =
	/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
export const instagramRegex = /(https?:\/\/www\.)?instagram\.com/;
export const websiteRegex =
	/^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
export const googleRegex = /(http(s)?\:\/\/)?(www\.)?google\.com/;
export const yelpRegex = /(http(s)?\:\/\/)?(www\.)?yelp\.com/;
export const houzzRegex = /(http(s)?\:\/\/)?(www\.)?houzz\.com/;
export const httpRegex = /http(s)?\:\/\//;
