import {string} from "@src/libs/yup";
import ccValidator from "card-validator";

export default string().test({
    name: "cc-exp-valid",
    message: "Credit card is invalid",
    test: (val) => {
        const {isValid} = ccValidator.expirationDate(val);
        return isValid;
    }
});

export const ccExpPotentiallyValid = string().test({
    name: "cc-exp-potentially-valid",
    message: "Credit card is invalid",
    test: (val) => {
        const {month, year} = ccValidator.expirationDate(val);
        const {isPotentiallyValid: isMonthPotentiallyValid} = ccValidator.expirationMonth(month || val.replace(/\D/g, ""));
        const {isPotentiallyValid: isYearPotentiallyValid} = ccValidator.expirationYear(year || val.replace(/\D/g, ""));
        return isMonthPotentiallyValid || isYearPotentiallyValid;
    }
});