import {string} from "@src/libs/yup";
import ccValidator from "card-validator";

export default string().test({
    name: "cc-valid",
    message: "Credit card is invalid",
    test: (val) => {
        const {isValid} = ccValidator.number(val);
        return isValid;
    }
});

export const ccPotentiallyValid = string().test({
    name: "cc-potentially-valid",
    message: "Credit card is invalid",
    test: (val) => {
        const {isPotentiallyValid} = ccValidator.number(val);
        return isPotentiallyValid;
    }
});