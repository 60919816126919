export * from "./validations";

import loadable from "@loadable/component";

export const ZipcodeTextField = loadable(
	() => import("./types/ZipcodeTextField"),
	{
		resolveComponent: ({ ZipcodeTextField }) => ZipcodeTextField,
	}
);
export const PasswordTextField = loadable(
	() => import("./types/PasswordTextField"),
	{
		resolveComponent: ({ PasswordTextField }) => PasswordTextField,
	}
);
export const EmailTextField = loadable(() => import("./types/EmailTextField"), {
	resolveComponent: ({ EmailTextField }) => EmailTextField,
});
export const PhoneTextField = loadable(() => import("./types/PhoneTextField"), {
	resolveComponent: ({ PhoneTextField }) => PhoneTextField,
});
export const BasicTextField = loadable(() => import("./types/BasicTextField"), {
	resolveComponent: ({ BasicTextField }) => BasicTextField,
});
export const CreditCardTextField = loadable(
	() => import("./types/CreditCardTextField"),
	{
		resolveComponent: ({ CreditCardTextField }) => CreditCardTextField,
	}
);
export const CreditCardCodeTextField = loadable(
	() => import("./types/CreditCardCodeTextField"),
	{
		resolveComponent: ({ CreditCardCodeTextField }) => CreditCardCodeTextField,
	}
);
export const CreditCardExpTextField = loadable(
	() => import("./types/CreditCardExpTextField"),
	{
		resolveComponent: ({ CreditCardExpTextField }) => CreditCardExpTextField,
	}
);
export const NameTextField = loadable(() => import("./types/NameTextField"), {
	resolveComponent: ({ NameTextField }) => NameTextField,
});
export const DateTextField = loadable(() => import("./types/DateTextField"), {
	resolveComponent: ({ DateTextField }) => DateTextField,
});
export const MoneyTextField = loadable(() => import("./types/MoneyTextField"), {
	resolveComponent: ({ MoneyTextField }) => MoneyTextField,
});
// export * from "./types/ZipcodeTextField";
// export * from "./types/PasswordTextField";
// export * from "./types/EmailTextField";
// export * from "./types/PhoneTextField";
// export * from "./types/BasicTextField";
// export * from "./types/CreditCardTextField";
// export * from "./types/CreditCardCodeTextField";
// export * from "./types/CreditCardExpTextField";
// export * from "./types/NameTextField";
// export * from "./types/DateTextField";
// export * from "./types/MoneyTextField";

export default loadable(() => import("./TextField"));
