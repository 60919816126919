import { string, ref } from "@src/libs/yup";
import ccValidator from "card-validator";

export default string().test({
	name: "cc-code-valid",
	message: "${name} is invalid",
	test: function (val) {
		const { createError } = this;
		const { size, name } = this.options.context;
		if (!size) return createError({ path: this.path, params: { name } });
		const { isValid } = ccValidator.cvv(val, size);
		if (!isValid) return createError({ path: this.path, params: { name } });
		return isValid;
	},
});

export const ccCodePotentiallyValid = string().test({
	name: "cc-code-potentially-valid",
	message: "${name} is invalid",
	test: function (val) {
		const { createError } = this;
		const { size, name } = this.options.context;
		if (!size) return createError({ path: this.path, params: { name } });
		const { isPotentiallyValid } = ccValidator.cvv(val, size);
		if (!isPotentiallyValid)
			return createError({ path: this.path, params: { name } });
		return isPotentiallyValid;
	},
});
